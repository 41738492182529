import { Box, Button, CardMedia, Typography } from "@mui/material";
import Link from "next/link";
import { CarouselCardData as CardData } from "../carousal/carousal_card";
import cssStyles from "./shop_products.module.scss";
import personaliseIcon_1 from '../../assets/images/personalise_icon_1.png';
import view_in_your_room_icon from '../../assets/images/view_in_your_room_icon.png';
import AppleDownloadPopupLayerButton from "../popups/AppleDownloadPopupLayerButton";
import Image from 'next/image';
import { BorderRight } from "@mui/icons-material";
import { useRouter } from "next/router";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import BestSellerIcon from '../../../public/assets/img/bestseller-icon@1x.png'
import FavoriteButton from "../FavoriteButton";
import { getUserInfo } from "@/utils/useUserInfo";

export default function GenerateProductCards(cards: CardData[], actionFn?: Function,
    height?: string, pageName?: string,
    hoverState?:any,
    onHover?:any, onHoverOut?: any, favorites?: any, setFavorites?:any, customerId?:any ): any[] {
    const productCardsEls: any[] = [];

    cards?.forEach((card: CardData, index: number) => {
        const isBestSeller = card?.isBestSeller || false; // Default to false
        console.log('Card Data:', card); // Log entire card object
        console.log('Is Best Seller:', card?.isBestSeller ?? 'Undefined');

        const handleCustomerRecentlyViewed = async (card: any) => {

            const userInfo = await getUserInfo("",true);
    
            const recentlyViewedURL = `https://nestingale.com/v1/api/customer/recently-viewed?customerId=${userInfo.cId}&itemId=${card.id}&type=product`;
          
            try {
              console.log('Posting data to', recentlyViewedURL, 'to track customerRecentlyViewed');
          
              const response = await fetch(recentlyViewedURL, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  customerId: userInfo.cId,
                  itemId: card.id,
                  type: 'product',
                  image: card.imageUrl,
                  name: card.name,
                  price: card.price,
                  comparePrice: card.comparePrice,
                  model3dUrl: card.model3dUrl || null,
                }),
              });
          
              if (!response.ok) {
                const errorDetails = await response.json(); // If server sends error details
                throw new Error(
                  `Error: ${response.status} - ${response.statusText}. Details: ${JSON.stringify(errorDetails)}`
                );
              }
          
              console.log('Successfully tracked recently viewed item:',card.id);
            } catch (error) {
              console.error('Error tracking recently viewed item:', error);
            }
        };

        productCardsEls.push(
            <Box component='div' className={cssStyles["card"]} 
                 key={index} onClick={(e: any) => {
                    actionFn && actionFn(e, index, card, pageName)
                    handleCustomerRecentlyViewed(card);
                }}
                 style={{
                    width: parseInt(height as string ?? "200"),
                    height: parseInt(height as string ?? "200")+150
                 }}
                 >

                <div className={cssStyles["image-wrapper"]}>
                    {/* BestSeller Icon */}
                    {card?.isBestSeller && (
                      <div className={cssStyles["bestSeller-icon"]}>
                        <img src={BestSellerIcon.src} alt="Best Sellerzzzz" />
                      </div>
                    )}

                    <div className={cssStyles["fav-icon"]}>
                        {card.id && <FavoriteButton customerId={customerId} productId={card.id} productName={card.name} price={card.price} comparePrice={card.comparePrice} imageUrl={card.imageUrl} model3dUrl={card.model3dUrl} favorites={favorites} setFavorites={setFavorites}/>}
                    </div>

                    <a id={`${pageName}_product_${card.id}`} href={card.link ?? "#"}>
                        <Image
                            src={card.imageUrl || ""}
                            alt={card.name || ""}
                            width={parseInt(height as string ?? "200")} // replace with your desired width
                            height={parseInt(height as string ?? "200")}
                            objectFit="contain"
                            style={{
                                objectFit: "contain",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderRadius: '40px',
                                marginBottom: '10px',
                                borderColor: "lightgray",
                                padding: '20px',
                                /*borderColor: (theme) => theme.palette.secondary.light,
                                ':hover': { borderColor: (theme) => theme.palette.primary.main } */
                            }}
                        />

                    </a>
                </div>

                <div className={cssStyles["text"]}>

                    <Typography sx={{ margin: '0px', fontWeight: 700 }} textAlign={"center"} mt={1} mb={2} noWrap={false} gutterBottom variant="h6" component="div">
                        {card.name}
                        {(card.otherSizes?.length > 0 && card.otherColors?.length > 0) ? ' (' + (card.otherSizes.length+1) + ' sizes, '+ (card.otherColors?.length+1) +' colors)' : ''}
                        {(card.otherSizes?.length > 0 && !card.otherColors) ? ' (' + (card.otherSizes.length+1) + ' sizes)' : ''}
                        {(!card.otherSizes && card.otherColors?.length > 0) ? ' (' + (card.otherColors?.length+1) +' colors)' : ''}
                    </Typography>
                    {
                        (card.price != undefined && card.price > 0 && card.comparePrice != undefined && card.comparePrice > 0 && card.price < card.comparePrice) ?
                            <Typography sx={{ margin: '0px', fontWeight: 700}} textAlign={"center"} variant="subtitle2">
                                Price : $<Box component="span" sx={{ textDecoration: "line-through", textDecorationThickness: "2px", textDecorationColor: (theme: { palette: { primary: { main: any; }; }; }) => theme.palette.primary.main, marginRight: "10px" }}>{card.comparePrice}</Box>
                                <Box component="span" sx={{ color: "primary_extent.mainDark" }} >${card.price}</Box>
                                <Box component="span" className={cssStyles["sale"]}>Sale</Box>
                            </Typography>
                            :
                            card.price != undefined && card.price > 0 &&
                            <Typography sx={{ margin: '0px', fontWeight: 700 }} textAlign={"center"} variant="subtitle2" >
                                Price : $<span>{card.price}</span>
                            </Typography>
                    }
                    {card.expiresOn && <Typography sx={{ margin: '0px', color: 'primary.main', fontWeight: 700 }} textAlign={"center"} mt={1} mb={2} noWrap={false} gutterBottom variant="h6" component="div">
                        {card.expiresOn}
                    </Typography>}
                    {/* <Typography sx={{ margin: '0px', fontWeight: 700, color: 'primary_extent.mainDark' }} textAlign={"left"} mt={1} mb={2} noWrap={false} gutterBottom variant="h6" component="div">FREE SHIPPING - <Box component="span" sx={{ color: "primary.main" }} >Ships in 48 hrs</Box></Typography> */}
                    <Typography sx={{ margin: '0px', fontWeight: 700, color: 'primary_extent.mainDark' }} textAlign={"center"} mt={1} mb={2} noWrap={false} gutterBottom variant="h6" component="div">FREE SHIPPING</Typography>
                    { card.viewInYourRoomLink && card.model3dUrl && <>
                        <div className={`${cssStyles["bottom-icon-dsk"]}`}>
                            <Link id={`${pageName}_view_in_your_room_icon_${card.id}`} href={card.viewInYourRoomLink} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            {card.model3dUrl &&
                                            <div className={cssStyles["button-3d"]}>
                                                <ViewInArIcon />
                                            </div>
                                        }
                                <Typography sx={{ margin: '0px', fontWeight: 700, color: "primary_extent.mainDark", display: 'flex', alignItems: 'center' }} textAlign={"left"} noWrap={false} gutterBottom variant="h6" component="div">
                                    View this product in your room
                                </Typography>
                            </Link>
                        </div>
                        <div className={`${cssStyles["bottom-icon-mobile"]}`}>
                            <AppleDownloadPopupLayerButton openedFrom={pageName}
                                buttonClass={{
                                    padding: '0px',
                                    margin: '0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '25px',
                                    width: '25px',
                                    minWidth: '25px',
                                    backgroundColor: 'white',
                                    borderRadius: '50%'
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {card.model3dUrl &&
                                            <div className={cssStyles["button-3d"]}>
                                                <ViewInArIcon />
                                            </div>
                                        }
                                    <Typography sx={{ margin: '0px', fontWeight: 700, color: "primary_extent.mainDark", display: 'flex', alignItems: 'center' }} textAlign={"center"} noWrap={false} gutterBottom variant="h6" component="div">
                                        View this product in your room
                                    </Typography>
                                </div>
                            </AppleDownloadPopupLayerButton>
                        </div>
                    </>
                    }
                </div>

                {card.viewMoreLink && <Link href={card.viewMoreLink} className="see-more-like-this">See more like this</Link>}
            </Box>);
    })
    return productCardsEls;
}


