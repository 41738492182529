import React, { useState } from 'react';
import {Button } from "@mui/material";
import AppleDownloadPopupLayer from './AppleDownloadPopupLayer';

interface AppleDownloadPopupLayerButtonProps {
  buttonClassName?: string;
  buttonClass?: {};
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'inherit' | 'default';
  children?: React.ReactNode; //| HTMLElement
  openedFrom?: string;
}

const AppleDownloadPopupLayerButton: React.FC<AppleDownloadPopupLayerButtonProps> = (
  {buttonClassName, buttonClass, children, color, openedFrom}: AppleDownloadPopupLayerButtonProps) => {
    
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  return (
    <>
      {isPopupOpen && <AppleDownloadPopupLayer closeLayer={closePopup} isPopupOpen={isPopupOpen} openedFrom={openedFrom} />}
      <Button color={ color == 'secondary' ? 'secondary' : 'primary' } sx={{whiteSpace: 'nowrap', ...buttonClass}} onClick={openPopup} className={buttonClassName}>{children}</Button>
    </>
  );
};

export default AppleDownloadPopupLayerButton;
