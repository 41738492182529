import React from "react";
import { Typography, Grid} from "@mui/material";
import cssStyles from "../../components/products/products.module.scss";
import appleDownload from '../../assets/images/apple_app_download.png';
import mwebPopup from '../../assets/images/mweb_popup.png';
import Layer from "@/components/layer";
import { isIos } from "@/utils";

interface AppleDownloadPopupProps {
    closeLayer: () => void;
    isPopupOpen: boolean;
    openedFrom?: string;
  }

const AppleDownloadPopupLayer: React.FC<AppleDownloadPopupProps> = ({ closeLayer, isPopupOpen, openedFrom }) => {

    const ApplePopupContent = () => {
        return (
            <> 
            { isIos() === true ? 
            <div className={cssStyles.instruction_tip}>
                <Typography variant="h6" className={cssStyles.lets_design}>Let&apos;s design your room!</Typography>                
                <Typography variant="h3">Use app for an <i>amazing</i> design experience!</Typography>
                        <img src={mwebPopup.src} alt="" style={{maxWidth: '95%', margin: 'auto', display: 'flex'}} />
                <div className={cssStyles.app_download}>
                    <a id={`${openedFrom}.appstore_link_clicked`} href="https://apps.apple.com/us/app/nestingale/id6461722582"><img src={appleDownload.src} /></a>
                </div>
            </div> 
            :             
            <div className={cssStyles.instruction_tip}>
                <Typography variant="h2" sx={{padding: '10px'}}>
                    We are building exciting features for your phone. Meanwhile, please check out these immersive features on the desktop version of www.nestingale.com!
                </Typography>
            </div> }
            </>
        );
    }

    return (
        <>
        <Layer
            open={isPopupOpen}
            onClose={closeLayer}
            dialogTitle=""
            Element={ApplePopupContent}
            dialogContentClassName={"handle-app-download-Layer"}
            maxWidth="sm"
            dialogContentComponentProps="" />
        </>
    )
}

export default AppleDownloadPopupLayer;