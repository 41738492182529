import { CardData, Product } from "@/store/productSlice";
import { CarouselCardData } from "../components/carousal/carousal_card";

export interface ProductData {
    productSubTypeId?: string;
    productTypeId?: string;
    productId?: string;

    availability?: string;
    
    name?: string;
    displayName?: string;
    productName?: string;
    productTypeName?: string;
    productSubTypeName?: string;

    productImage?: string;
    imageUrl?: string;

    description: string;
    price?: number;
    comparePrice?: number;
    isBestSeller?:boolean;
}
export interface Address {
    address1: string;
    address2: string;
    address3: string;
}
export interface Vendor {
   vendorId: string;
   vendorName: string;
   vendorDisplayName?: string;
   vendorLogo?: string ;
   vendorAddress? : Address;
    vendorYoutubeLink?: string;
    landingPage?: string;
}

export const prepareCardsForCategoryCarousel = (data: ProductData[], brandPathEntry?: string): CarouselCardData[] => {
    const categoriesCards  = data?.filter((eachSlide: ProductData) => eachSlide.name && eachSlide.imageUrl).map((eachSlide: ProductData) => {
        const id = eachSlide.productTypeId || eachSlide.productId;
        const slideCard: CarouselCardData = {
            id: id,
            name: eachSlide.displayName,
            imageUrl: eachSlide.imageUrl,
            link: `${brandPathEntry}/products/${eachSlide.name}`,
            imageHeight: "20rem",
            height: "25rem",
            trackObj: {
                clickId: "shopByCategory",
                event: "Shop By Category",
                name: "Selected Product Category",
                value: eachSlide.displayName,
                valuePropName: 'selectedProductCategoryValue'
            },
            isBestSeller: eachSlide.isBestSeller
        }
        return slideCard
    });
    //console.log("Categories-Cards=",categoriesCards);    
    return categoriesCards;
}

export const prepareCardsForRoomProductsCarousel = (data: ProductData[], roomName : string, styleName: string, showViewMore: boolean = true , brandPathEntry?: string): CarouselCardData[] => {
    const productsCards  = data?.map((eachSlide: ProductData) => {
        let showMoreLink = `${brandPathEntry}/products/${eachSlide.productTypeName}`;
        if(eachSlide.productSubTypeName) {
            showMoreLink += `/type/${eachSlide.productSubTypeName}`;
        }
        showMoreLink += `?room=${roomName}&filters=style:${styleName}`;
        let productLink = `${brandPathEntry}/product/${eachSlide.productId}?room=${roomName}&style=${styleName}&type=${eachSlide.productTypeName}`;
        if(eachSlide.productSubTypeName) {
            productLink += `&subtype=${eachSlide.productSubTypeName}`;
        }

        let price = (eachSlide.price && !isNaN(eachSlide.price) && eachSlide.price > 0) ? eachSlide.price : 0;
        let comparePrice = (eachSlide.comparePrice && !isNaN(eachSlide.comparePrice) && eachSlide.comparePrice > 0) ? eachSlide.comparePrice : 0;


        return {
            id: eachSlide.productId,
            name: eachSlide.productName,
            availability: eachSlide.availability,
            imageUrl: eachSlide.productImage,
            link: productLink,
            viewMoreLink: showViewMore ? showMoreLink : null,
            imageHeight: "18rem",
            height: "25rem",
            width: "24rem",
            price,
            comparePrice,
            isBestSeller: eachSlide.isBestSeller
        } as CarouselCardData;
    });
    //console.log("Products-Cards=",productsCards);    
    return productsCards;
}

export const prepareCardsForBuilderProductsGrid = (data: Product[] , brandPathEntry?: string, vendorsData?:  any): CarouselCardData[] => {
    const showViewMore = false;
    const productsCards  = data?.map((eachSlide: Product) => {
        let vendorName = vendorsData?.vendors?.find((vendorObj: any) => vendorObj.vendorName === eachSlide.vendorName)?.vendorDisplayName;
//        console.log("eachSlide:::::---->", eachSlide);
        return {
            id: eachSlide.id,
            name: eachSlide.name,
            imageUrl: eachSlide.image,
            link: eachSlide.pdpUrl,
            viewMoreLink: showViewMore ? eachSlide.viewMoreUrl : null,
            imageHeight: "18rem",
            height: "25rem",
            width: "24rem",
            price: eachSlide.price,
            model3dUrl: eachSlide.model3dUrl,
            comparePrice: eachSlide.comparePrice,
            expiresOn: eachSlide.expiresOn,
            viewInYourRoomLink: eachSlide.viewInYourRoomLink,
            vendorName,
            priceRange: eachSlide.priceRange,
            otherColors: eachSlide.otherColors,
            otherSizes: eachSlide.otherSizes,
            otherFabrics: eachSlide.otherFabrics,
            configurationDisplayName: eachSlide.configurationDisplayName,
            configurationIcon: eachSlide.configurationIcon,
            colorVariants: eachSlide.colorVariants,
            isBestSeller: eachSlide.isBestSeller
        } as CarouselCardData;
    });
    //console.log("Products-Cards=",productsCards);    
    return productsCards;
}

export const prepareCardsForCategoriesGrid = (data: CardData[] , brandPathEntry?: string, vendorName?:  string): CarouselCardData[] => {
    const showViewMore = false;
    const productsCards  = data?.map((eachSlide: CardData) => {
        return {
            id: eachSlide.trackId || eachSlide.title,
            name: eachSlide.displayName  || eachSlide.title,
            imageUrl: eachSlide.imageUrl,
            link: eachSlide.link,
            imageHeight: "18rem",
            height: "25rem",
            width: "24rem",
            description: eachSlide.description,
            vendorName
        } as CarouselCardData;
    });
    //console.log("Products-Cards=",productsCards);    
    return productsCards;
}

export const prepareCardsForVendorsCarousel = (data: Vendor[],excludeBaseURI: boolean) => {
    const vendorCards  = data?.map((vendor: Vendor) => {
        return {
            id: vendor.vendorId,
            name: vendor.vendorDisplayName,
            imageUrl: vendor.vendorLogo,
            link: vendor.landingPage ? vendor.landingPage : `/${vendor.vendorName}/rooms`,
            excludeBaseURI,
            imageHeight: "8rem",
            height: "12rem",
            trackObj: {
                clickId: "shopByBrand",
                event: "Shop By Brand",
                name: "Selected Brand",
                valuePropName: 'selectedBrandValue',
                value: vendor.vendorDisplayName
            }
        } as CarouselCardData;
    });
    //console.log("Vendor-Cards=",vendorCards);    
    return vendorCards;
}