import { useState, useEffect } from "react";
import { useShopify } from '../store/shopifyReducer'
import { userInfo } from "os";
import { getUserInfo, getUserCartInfo } from "@/utils/useUserInfo";

function useShopifyCheckout() {
    const {
        checkoutState,
        createCheckout,
        addVariant,
        getClient,
        checkoutID
    } = useShopify();

    const [products, setProducts] = useState<any>([])
    const [checkoutLoaded, setCheckoutLoaded] = useState(false);
    const [availabilityUpdated, setAvailabilityUpdated] = useState(false);
    const [productAvailabilty, setProductAvailability] = useState('unavailable');
    const [productVariantId, setProductVariantId] = useState<any>([]);
    //the following two effects, loadShopifyCheckout and updateProductAvailability ensure that we
    //handle 'Add to cart'/'Unavailable' situations correctly. this code ideally should
    //go into a custom hook and we should use this hook both here and project_details page.
    const [userInfo, setUserInfo] = useState<any>({})
    useEffect(() => {
        async function fetchLatestUserInfo() {
            const res = await getUserInfo('', true)
            await getUserCartInfo(res)
            setUserInfo(res)
        }
        fetchLatestUserInfo()
    }, [])
    useEffect(() => {
        async function loadShopifyCheckout() {
            let checkoutId = null;
            if (Object.keys(userInfo).length === 0) return
            // if (typeof window !== 'undefined' && userInfo?.status == 'DONE') {
            try {
                checkoutId = userInfo?.crtD?.cartId
                if (!checkoutId) {
                    const cartData = await getUserCartInfo(userInfo);
                    console.log('cartData---->>: ', cartData);
                    checkoutId = cartData.cartId;
                }
                //@ts-ignore
                checkoutId = checkoutId || window.getCookie(CART_TOKEN);
                checkoutId = (checkoutId == "null" || checkoutId == "undefined") ? null : checkoutId;

                if (!checkoutId) {
                    //@ts-ignore
                    checkoutId = window.getCookie(GCART_TOKEN);
                    checkoutId = (checkoutId == "null" || checkoutId == "undefined") ? null : checkoutId;
                }
                //if checkout is empty create checkout or if checkout Id is present get the data from shopify
                console.log('creating checkout with  checkoutId----: ', checkoutId);
                await createCheckout({ checkoutId });
            } catch (error) {
                console.error('-----product-detail-----error creating checkout', error);
                setProductAvailability('unavailable');
            }
            setCheckoutLoaded(true);
            // }
        }
        loadShopifyCheckout();
    }, [userInfo])

    useEffect(() => {
        if (products.length === 0) return
        async function updateProductAvailability() {
            setProductVariantId([])
            for (let eachProduct of products) {
                const shopifyProductId = 'gid://shopify/Product/' + eachProduct.productShopifyId;
                console.log('--------ProductDetails fetchShopifyInformation--------', 'shopifyProductId:', shopifyProductId);
                //@ts-ignore
                try {
                    const shopifyProd = await getClient().product.fetch(shopifyProductId);
                    console.log('--------ProductDetails fetchShopifyInformation--------', 'shopify Prod: ', shopifyProd);
                    if (shopifyProd) {
                        const variant = shopifyProd?.variants[0];
                        if (variant) {
                            setProductVariantId((prevState:any) => [...prevState, { variantId: variant.id, quantity: 1 }]);

                            setProductAvailability('available');
                        }
                    }
                } catch (error) {
                    console.error("Error fetching product from shopify", error);
                    setProductAvailability('unavailable');
                }
                setAvailabilityUpdated(true);
            }

        }
        if (checkoutLoaded) {
            updateProductAvailability();
        }
    }, [checkoutLoaded, products])
    return [productVariantId, setProducts, userInfo]
}
export default useShopifyCheckout