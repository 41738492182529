'use-client'
import cssStyles from './designer_carousel.module.scss'
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material"
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useRouter } from "next/router";
const apiStatusConstants = {
    initial: 'INITIAL',
    success: "SUCCESS",
    failure: 'FAILURE',
    inProgress: "IN_PROGRESS"
}
function DesignerCarousel(props: any) {
    const { onClose, open } = props
    const router = useRouter()
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial)
    const [designerDetails, setDesignerDetails] = useState([])
    // let designerDetails = [
    //     {
    //         id: 1,
    //         coverImage: coverImage.src,
    //         profileImage: profileImage.src,
    //         designerName: 'Crystal Bright',
    //         designerDescription: 'Sensory Designer, Vibe Curator, & AI innovator',
    //         tags: ['Sensory Design', 'Vibe Curation', 'AI-Driven Design', 'Sustainable Practices', 'Experiential Design', 'More...'],
    //         buttonText: 'Learn More',
    //         cls: 'mySlides1'
    //     },
    //     {
    //         id: 2,
    //         coverImage: coverImage.src,
    //         profileImage: profileImage.src,
    //         designerName: 'Crystal Bright',
    //         designerDescription: 'Sensory Designer, Vibe Curator, & AI innovator',
    //         tags: ['Sensory Design', 'Vibe Curation', 'AI-Driven Design', 'Sustainable Practices', 'Experiential Design', 'More...'],
    //         buttonText: 'Learn More',
    //         cls: 'mySlides2'

    //     },
    //     {
    //         id: 3,
    //         coverImage: coverImage.src,

    //         profileImage: profileImage.src,
    //         designerName: 'Crystal Bright',
    //         designerDescription: 'Sensory Designer, Vibe Curator, & AI innovator',
    //         tags: ['Sensory Design', 'Vibe Curation', 'AI-Driven Design', 'Sustainable Practices', 'Experiential Design', 'More...'],
    //         buttonText: 'Learn More',
    //         cls: 'mySlides3'

    //     },
    //     {
    //         id: 3,
    //         coverImage: coverImage.src,

    //         profileImage: profileImage.src,
    //         designerName: 'Crystal Bright',
    //         designerDescription: 'Sensory Designer, Vibe Curator, & AI innovator',
    //         tags: ['Sensory Design', 'Vibe Curation', 'AI-Driven Design', 'Sustainable Practices', 'Experiential Design', 'More...'],
    //         buttonText: 'Learn More',
    //         cls: 'mySlides3'

    //     },
    // ]
    const CustomRight = ({ onClick }: any) => (
        <button className={`${cssStyles["react-multiple-carousel__arrow"]} ${cssStyles["react-multiple-carousel__arrow--right"]}`} onClick={onClick}>
            <NavigateNextIcon />
        </button>
    );
    const CustomLeft = ({ onClick }: any) => (
        <button className={`${cssStyles["react-multiple-carousel__arrow"]} ${cssStyles["react-multiple-carousel__arrow--left"]}`} onClick={onClick}>
            <NavigateBeforeIcon />
        </button>
    );
    useEffect(() => {
        const env = process.env.NEXT_PUBLIC_APP_ENV === 'qa' ? 'qa' : 'prod';
        async function fetchDesignerDetails() {
            setApiStatus(apiStatusConstants.inProgress)
            try{
                const response: any = await fetch(`https://d2ffb7z1ailwvo.cloudfront.net/${env == 'qa' ? 'stage' : 'prod'}/designers/index.json`)
                if (response.ok) {
                    const resdata = await response.json()
                    setDesignerDetails(resdata?.list)
                    setApiStatus(apiStatusConstants.success)
                } else {
                    setApiStatus(apiStatusConstants.failure)
                }
            }catch(error){
                console.log('error at fetching designers profile',error)
            }
        }
        fetchDesignerDetails()

    }, [])
    return <Dialog open={open} maxWidth="xl" fullWidth sx={{
        '& .MuiDialog-paper': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            margin: 0,
            width: '100%'
        },
    }}>
        <DialogContent sx={{
            position: 'relative',
            padding: 0
        }}>
            <IconButton
                onClick={onClose}
                style={{
                    position: "absolute",
                    top: 0,
                    right: 10,
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "50%",
                    padding: "6px",
                    zIndex: 10,
                }}
            >
                <Close />
            </IconButton>
            <Carousel afterChange={(previousSlide, { currentSlide }) => {
                console.log("currentSlide", currentSlide);
            }}
                responsive={{
                    desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 3,
                        slidesToSlide: 1,


                    },
                    tablet: {
                        breakpoint: { max: 1024, min: 769 },
                        items: 2,
                        slidesToSlide: 1,

                    },
                    mobile: {
                        breakpoint: { max: 768, min: 0 },
                        items: 1,
                        slidesToSlide: 1,
                    }
                }}
                partialVisible={false}
                ssr={true}
                showDots={false}
                autoPlay={false}
                swipeable={true}
                keyBoardControl={true}
                itemClass={cssStyles["carousel-item-spacing"]}
                containerClass={cssStyles["carousel-container"]}
                customRightArrow={<CustomRight />}
                customLeftArrow={<CustomLeft />}
            >
                {
                    designerDetails?.map((eachDesigner: any) => {
                        return <div key={eachDesigner?.trackId} className={cssStyles.card}>
                            <div className={cssStyles.cardTop} style={{ backgroundImage: `url(${eachDesigner?.profileImage?.backgroundImgSrc})` }}></div>
                            <div className={cssStyles.cardProfile}>
                                <div className={cssStyles.profileImage} style={{ backgroundImage: `url(${eachDesigner?.profileImage?.src})` }}>
                                </div>
                            </div>
                            <div className={cssStyles.contentContainer}>
                                <h3>{eachDesigner?.name}</h3>
                                <p>{eachDesigner?.title}</p>
                                <div className={cssStyles.tagsContainer}>
                                    {
                                        eachDesigner?.keywords?.slice(0,7).map((eachTag: any) => <span key={eachTag} className={cssStyles.tagCloud}>{eachTag}</span>)
                                    }
                                    {/* <span className={cssStyles.tagCloud} >More...</span> */}
                                </div>
                                <button onClick={() => { router.push(eachDesigner?.link) }}>View Profile</button>
                            </div>

                        </div>
                    })
                }
            </Carousel>
        </DialogContent>
    </Dialog>
}
export default DesignerCarousel