const userInfo: { [key: string]: string | boolean } = {};

export const setUserId = (userId: string) => {
    userInfo.userId = userId;
}

export const setUserInfo = (userData: { [key: string]: string | boolean }) => {
    userInfo.userId = userData.cId;
    userInfo.emailAddress = userData.emailAddress;
    userInfo.userName = userData.userName;
    userInfo.isLoggedIn = !(userData.unauthorized === true);
}

export const getUserId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const customerId = urlParams.get('cid');
    if (customerId !== null) {
        return customerId;
    }
    return userInfo.userId as string;
}

export const getUserName = () => {
    return userInfo.userName as string;
}

export const isLoggedInUser = () => {
    return userInfo.isLoggedIn;
}

