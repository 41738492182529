import React, { useEffect, useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import axios from 'axios';
import { postFavorite, deleteFavorite } from '@/utils/useUserInfo';
import cssStyles from './favorite.module.scss';
import { gtmClickTracking } from '@/utils';
import router from 'next/router';

interface FavoriteButtonProps {
    customerId: string;
    productId: string; // Unique product ID
    productName?: string; // Product name for fallback identifier
    favorites: any;
    price: number | undefined;
    comparePrice: number | undefined;
    model3dUrl: string | undefined;
    imageUrl: string | undefined;
    setFavorites:any
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({ customerId, productId, productName, price, comparePrice, model3dUrl, imageUrl, favorites={},setFavorites=()=>{}}) => {

    // Generate a unique identifier for the product
    const generateProductIdentifier = (): string => {
        return productId || `${productName?.replace(/\s+/g, '-').toLowerCase() || 'unknown'}`;
    };

    const productIdentifier = generateProductIdentifier();

    // Toggle favorite status
    const toggleFavorite = async () => {
        const newFavorites = { ...favorites, [productIdentifier]: !favorites[productIdentifier] };
        setFavorites(newFavorites);
        const action = newFavorites[productIdentifier] ? 'favorite' : 'unfavorite';

        gtmClickTracking({
            event: `Pdp_${action}_clicked`,
            page: router.pathname,
            item_id: productIdentifier,
        });
        console.log('FavoriteButton:customerId->', customerId, 'favorites: ', favorites)
        if (customerId) {
            try {
                const requestData = {
                    customerId: customerId,
                    itemId: productIdentifier,
                    type: 'product',
                    image: imageUrl,
                    name: productName,
                    price: price,
                    comparePrice: comparePrice,
                    model3dUrl: model3dUrl
                };
                newFavorites[productIdentifier] ?
                    await postFavorite(customerId, requestData) :
                    await deleteFavorite(customerId, requestData);

                console.log(
                    `Favorite status ${newFavorites[productIdentifier] ? 'added' : 'removed'} on backend`
                );
            } catch (error) {
                console.error('Error updating favorite status:', error);
            }
        } else {
            const localFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
            const updatedFavorites = newFavorites[productIdentifier]
                ? [...localFavorites, productIdentifier]
                : localFavorites.filter((id: string) => id !== productIdentifier);
            localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
        }
    };

    const isProdFavorited = favorites[productIdentifier] || false;

    return (
        <div
            className={cssStyles['favorite-icon']}
            onClick={toggleFavorite}
            style={{ cursor: 'pointer' }}
        >
            {isProdFavorited ? (
                <FavoriteIcon sx={{ color: '#FE6526', fontSize: 30 }} />
            ) : (
                <FavoriteBorderIcon sx={{ color: 'rgb(37 40 43)', fontSize: 30 }} />
            )}
        </div>
    );
};

export default FavoriteButton;